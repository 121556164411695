#chartjs-tooltip {
  background: lighten(#fff, 4%);
  border-radius: 4px;
  padding: 10px;
  box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.6);
  transition: 0.3s ease;

  table {
    tr {
      th {
        color: "#858585";
      }

      td {
        display: flex;
        align-items: center;
        line-height: 1px;
        padding-top: 5px;
        padding-bottom: 5px;

        &:first-letter {
          text-transform: uppercase;
        }

        span {
          width: 7px;
          height: 7px;
          display: inline-block;
          border-radius: 100px;
          margin-right: 7px;
          color: #001737;
        }
      }
    }
  }
}
