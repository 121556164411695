/** @format */

body {
  background-color: #f5fbff !important;
}

.buttonInput {
  font-size: "16px";
  text-transform: "capitalize";
  box-shadow: "none!important";
  width: "100%";
  border-radius: "2px";
  font-weight: "500";
  background: "#0073e6";
  color: " white";
  padding-left: "10px";
  justify-content: "center";
}

@media (min-width: 280px) {
  /* smartphones, iPhone, portrait 480x320 phones */
  .button {
    width: 94% !important;
    font-size: 12px !important;
  }
  .form-container {
    width: 320px !important;
  }

  .form-container .form-info-text {
    color: #677484;
    font-size: 12px;
  }

  .input-mobile {
    width: 60% !important;
    font-size: 12px !important;
  }

  input:focus {
    outline: none !important;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
    margin: 0;
  }

  textarea:focus {
    overflow: auto !important;
    outline: none !important;

    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;

    resize: none;
    /*remove the resize handle on the bottom right*/
  }

  Firefox */ input[type="number"] {
    -moz-appearance: textfield !important;
  }

  .plr-24 {
    padding-left: 12px !important;
    padding-right: 12px !important;
  }
}

@media (min-width: 320px) {
  /* smartphones, iPhone, portrait 480x320 phones */
  .button {
    width: 95% !important;
    font-size: 14px !important;
  }

  .form-container .form-info-text {
    color: #677484;
    font-size: 14px;
  }

  .input-mobile {
    width: 68% !important;
  }

  .plr-24 {
    padding-left: 0px !important;
    padding-right: 16px !important;
  }
}

@media (min-width: 360px) {
  /* smartphones, iPhone, portrait 480x320 phones */
  .button {
    width: 90% !important;
    font-size: 14px !important;
    text-align: center !important;
  }

  .form-container .form-info-text {
    color: #677484;
    font-size: 14px;
  }

  .input-mobile {
    width: 70% !important;
  }
}

@media (min-width: 481px) {
  /* smartphones, iPhone, portrait 480x320 phones */
  .button {
    width: 63% !important;
    font-size: 16px !important;
    text-align: center !important;
  }

  .form-container .form-info-text {
    color: #677484;
    font-size: 16px;
  }

  .input-mobile {
    width: 50% !important;
    font-size: 16px !important;
  }
}

@media (min-width: 576px) {
  .col-sm-4 {
    flex: 0 0 33.333333%;
    max-width: 30.333333% !important;
  }

  form-container .form-info-text {
    color: #677484;
    font-size: 16px !important;
  }
}

@media (min-width: 641px) {
  /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
  .button {
    width: 63% !important;
    font-size: 16px !important;
  }

  .form-container .form-info-text {
    color: #677484;
    font-size: 16px !important;
  }

  .input-mobile {
    width: 50% !important;
    font-size: 16px;
  }
}

@media (min-width: 768px) {
  /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
  .button {
    width: 70% !important;
    font-size: 16px !important;
  }

  .form-container .form-info-text {
    color: #677484;
    font-size: 16px !important;
  }

  .input-mobile {
    width: 50% !important;
    font-size: 16px;
  }

  .col-sm-4 {
    flex: 0 0 33.333333%;
    max-width: 55.333333% !important;
  }
}

@media (min-width: 961px) {
  /* tablet, landscape iPad, lo-res laptops ands desktops */
  .button {
    width: 60% !important;
    font-size: 16px !important;
  }

  .input-mobile {
    width: 52% !important;
    font-size: 16px;
    padding-top: 0px;
  }

  form-container .form-info-text {
    color: #677484;
    font-size: 16px !important;
  }
  .form-container {
    width: 40.3333% !important;
  }

  .container-center {
    width: 50%;
    margin: auto;
  }
}

@media (min-width: 1024px) {
  /* big landscape tablets, laptops, and desktops */
  .button {
    width: 70% !important;
    font-size: 16px !important;
  }

  .input-mobile {
    width: 52% !important;
    font-size: 16px;
  }
  .form-container {
    width: 40.3333% !important;
  }

  form-container .form-info-text {
    color: #677484;
    font-size: 16px !important;
  }

  .col-sm-4 {
    flex: 0 0 33.333333%;
    max-width: 40.333333% !important;
  }
}

@media (min-width: 1281px) {
  /* hi-res laptops and desktops */
  .button {
    width: 60% !important;
    font-size: 16px !important;
  }
  .form-container {
    width: 40.3333% !important;
  }
  .input-mobile {
    width: 52% !important;
    font-size: 16px;
  }

  form-container .form-info-text {
    color: #677484;
    font-size: 16px !important;
  }

  .col-sm-4 {
    flex: 0 0 33.333333%;
    max-width: 40.333333% !important;
  }
}
